<template>
  <div class="bodywhite">
    <div class="header-light">
      <div class="container">
        <div class="row">
          <div class="col-2">
          </div>
          <div class="col-8 col-center">
            <p class="pbolduppercase fontcolor-mainblue">Werte Erfassung</p>
          </div>
          <button class="col-2 col-right" @click="schliessenzuhome()">
              <img src="../assets/icons/close.svg">
          </button>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="paddingtop155">
        <p class="psemibold fontcolor-mainblue">Wie würdest du deinen allgemeinen Gesundheitszustand beschreiben?</p>
        
        <div class="margintop50">
            <input type="radio" id="befindenausgezeichnet" v-model="befinden" :value="1" name="befindenausgezeichnet"><label for="befindenausgezeichnet">Ausgezeichnet</label>
            <input type="radio" id="befindensehrgut" v-model="befinden" :value="2" name="befindensehrgut"><label for="befindensehrgut">Sehr gut</label>
            <input type="radio" id="befindengut" v-model="befinden" :value="3" name="befindengut"><label for="befindengut">Gut</label>
            <input type="radio" id="befindenwenigergut" v-model="befinden" :value="4" name="befindenwenigergut"><label for="befindenwenigergut">Weniger gut</label>
            <input type="radio" id="befindenschlecht" v-model="befinden" :value="5" name="befindenschlecht"><label for="befindenschlecht">Schlecht</label>
        </div>
<!-- <input type="radio" id="test2" name="jeff"><label for="test2">Steak</label> 
        <ul class="margintop50 liste" >  https://codepen.io/quinlo/pen/ReMRXz
          <li>
            <label for="befinden_ausgezeichnet">
              <input type="radio"  v-model="befinden" name="mode" :value="4" id="befindenausgezeichnet"/>
              <span>Ausgezeichnet</span>
            </label>
          </li>
          <li>
            <label for="befinden_sehrgut">
              <input type="radio" v-model="befinden" name="mode" :value="3" id="befindensehrgut"/>
              <span>Sehr gut</span>
            </label>
          </li>
          <li>
            <label for="befinden_gut">
              <input type="radio" v-model="befinden" name="mode" :value="2" id="befindengut"/>
              <span>Gut</span>
            </label>
          </li>
          <li>
            <label for="befinden_wenigergut">
              <input type="radio" v-model="befinden" name="mode" :value="1" id="befindenwenigergut"/>
              <span>Weniger gut</span>
            </label>
          </li>
          <li>
            <label for="befinden_schlecht">
              <input type="radio" v-model="befinden" name="mode" :value="0" id="befindenschlecht"/>
              <span>Schlecht</span>
            </label>
          </li>
        </ul>-->
      </div>
    </div>

    <div class="stepnavigation backgroundcolor-beige">
      <div class="container">
        <button type="button" value="Submit" class="buttonXS backgroundcolor-white col-2 col-center center" @click="werteabfragegewichtzuwerteabfrageblutdruck()">
          <img class="navicon:active" src="../assets/icons/arrow.svg">
        </button>
        <button type="button" value="Submit" class="buttonS fontcolor-white backgroundcolor-mainblue" @click="werteabfragegewichtzuwerteabfragesymptome()">
          <p class="pbold display-inline fontcolor-white">Weiter</p>
          <img class="display-inline" src="../assets/icons/arrow_white.svg" alt="">
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import router from '../router';

export default {
  components: {
  },
  mounted() {
    //this.focusInput();
  },
  data() {
      return {
        befinden: null,
      };
  },
  methods: {
    ...mapActions(['setWert']),
    schliessenzuhome() {
      router.push({path: '/home'})
    },
    werteabfragegewichtzuwerteabfrageblutdruck() {
      router.push({path: '/werteabfrageblutdruck'})
    },
    werteabfragegewichtzuwerteabfragesymptome() {

      if(this.befinden){
        this.setWert({
          name: "befinden", 
          wert: this.befinden,
        });
        setTimeout(()=> router.push({path: '/werteabfragesymptome'}), 100);
      }
    },
  }
}
</script>


<style scoped>

  label {
    display: inline-block;
    padding: 10px 15px;
    border: #0B1010 1px solid;
    border-radius: 40px; 
    color: #0B1010;
    display: flex;
    width: fit-content;
    text-align: right;
    margin-bottom: 10px;
  }
  input[type="radio"] {
      display:none;

  }
  input[type="radio"]:checked + label {
      color: #FFFFFF;
      fill: #ffffff;
      background-color: #0B1010;

  }


  .stepnavigation {
    background-color: #F6F6F6;
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 95px;
    bottom: 0;
    padding-top: 15px;
    z-index: 2;
  }

/*
  .input {
      color: #0B1010;
      text-align: right;
      font-size: 65px;
      font-weight: bold;
      width: 85%;
      border: none;
      height: 320px;
  }

  .input:focus {
  outline: none;
  }

  label {
      display: inline-block;
      margin-bottom: 0.5rem;
  }

  .liste li {
      list-style-type: none;
      text-align: right;
      padding: 12px 0px;
  }

  input[type="radio"] {
    opacity: 0%;
    position: absolute;
  }

  input[type="radio"] + span {
    color: #0B1010;
    border-radius: 40px;
    padding: 12px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    border: #0B1010 1px solid;
  }


  input[type="radio"]:checked + span {
    color: #FFFFFF;
    fill: #ffffff;
    background-color: #0B1010;
  }
  */
</style>